document.addEventListener('DOMContentLoaded', function() {
  if (window.sss && window.sss.adobedtmURL) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;

    if (script.readyState && (script.readyState === 'loaded' || script.readyState === 'complete')) {
      script.onreadystatechange = function () {
          window._satellite.pageBottom();
        }
    } else {
      script.onload = function() {
        window._satellite.pageBottom();
      };
    }

    script.src = window.sss.adobedtmURL;
    document.body.appendChild(script);
  }
});
